const FaqSectionData = [
    {
        "icon":"fa fa-question",
        "title":"Can I use Fastland for my clients?",
        "text":"With lots of unique blocks, you can easily build a page without coding. Build your next landing page. Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre."
    },
    {
        "icon":"fa fa-question",
        "title":"Do I get free updates?",
        "text":"With lots of unique blocks, you can easily build a page without coding. Build your next landing page. Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre."
    },
    {
        "icon":"fa fa-question",
        "title":"Does it work with WordPress?",
        "text":"With lots of unique blocks, you can easily build a page without coding. Build your next landing page. Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre."
    },
    {
        "icon":"fa fa-question",
        "title":"Will you provide support?",
        "text":"With lots of unique blocks, you can easily build a page without coding. Build your next landing page. Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre."
    },
    {
        "icon":"fa fa-question",
        "title":"Can I use Metsys for my clients?",
        "text":"With lots of unique blocks, you can easily build a page without coding. Build your next landing page. Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre."
    },
    {
        "icon":"fa fa-question",
        "title":"Can I use Metsys for my clients?",
        "text":"With lots of unique blocks, you can easily build a page without coding. Build your next landing page. Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre."
    }
]


export default FaqSectionData;